import Nav from '../components/Nav';
import Footer from '../components/Footer';

export default function Parceiros () {
    return (
    <>
    <Nav/>
    <div className="container">
        <div className="row" >
            <div className="col-md-12">
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}