import axios from 'axios';

const apiLink = "https://api.digitalworldpop.com/api/";

export const get = arg => {
    let param = arg?.data ? "/" + Object.keys(arg.data).map(key => arg.data[key]).join('/') : "";
//    console.log(apiLink+`${arg.endPoint}${param}`);
    return axios
        .get(apiLink+`${arg.endPoint}${param}`)
        .then(res => {
//            console.log(res.data);
            return res.data;
        })
        .catch();
}
