import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Painel from '../components/Painel';
import GoogleMapReact from 'google-map-react';
import Marker from '../components/Marker';

export default function Home () {
    const defaultProps = {
        center: {
          lat: -19.8950189,
          lng: -43.925193
        },
        zoom: 16
      };    

    return (
    <>
    <Nav/>
    <Painel/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px"}}>
        <h1 style={{fontWeight:"bold", margin:"40px"}}>NOSSA ATUAÇÃO</h1>
        <div className="container">
            <div className="row">
                <div className="col-md-4 text-center">
                    <img src="https://www.sitedeadvocacia.com.br/modelos/9/images/servidor.png" width="25%"  height="40%" /><br/>
                    <h3 style={{fontWeight:"bold"}}>Contencioso</h3>
                    <h4>Audiências online ou presenciais e processos.</h4>
                    <input type="button" value="Saiba mais..." style={{'background-color' : '#7D7373', color:"#FFFFFF", fontWeight:"bold"}} className="btn" />
                </div>
                <div className="col-md-4 text-center">
                    <img src="https://www.sitedeadvocacia.com.br/modelos/9/images/sms.png" width="25%"  height="40%" /><br/>
                    <h3 style={{fontWeight:"bold"}}>Assessoria</h3>
                    <h4>Serviços tributários, empresariais e demais...</h4>
                    <input type="button" value="Saiba mais..." style={{'background-color' : '#7D7373', color:"#FFFFFF", fontWeight:"bold"}} className="btn" />
                </div>
                <div className="col-md-4 text-center">
                    <img src="https://www.sitedeadvocacia.com.br/modelos/9/images/whatsapp.png" width="25%"  height="40%" /><br/>
                    <h3 style={{fontWeight:"bold"}}>Demandas tributárias e fiscais</h3>
                    <h4>Recuperação fiscal judicial.</h4>
                    <input type="button" value="Saiba mais..." style={{'background-color' : '#7D7373', color:"#FFFFFF", fontWeight:"bold"}} className="btn" />
                </div>
            </div>
        </div>
    </div>
    <div className="text-center" style={{'background-color' : '#7D7373', padding:"40px"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-4 text-left">
                    <h1 style={{fontWeight:"bold"}}>Fale Conosco</h1>
                    <br/>
                    <p style={{fontWeight:"bold"}}>
                    <h4 style={{fontWeight:"bold"}}>Endereço:</h4>
                    Rua Coronel Praes, 349/401<br/>
                    sagrada família, BH/MG, CEP: 31035-590<br/>
                    Cidade - MG<br/><br/>

                    <h4 style={{fontWeight:"bold"}}>Telefone:</h4>
                    (31) 97160-0615<br/>
                    </p>
                </div>
                <div className="col-md-4 text-right">
                    <div class="form-group">
                        <input type="text" placeholder="Nome*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="E-mail*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="Assunto*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <textarea placeholder="Mensagem*" className="form-control"></textarea>
                    </div>
                    <input type="button" value="Enviar" style={{'background-color' : '#c7c0b8', fontWeight:"bold"}} className="btn" />
                </div>
                <div className="col-md-4 text-right">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAgpTQGWeHKOoMJIPnT1mL04HfKuWrvGgM" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    >                
                    <Marker
                        id={1}
                        lat={-19.8950189}
                        lng={-43.925193}
                        text="Advocacia"
                    />      
                </GoogleMapReact>
              </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}