export default function ArticleItem(props){
    let data = props.data;
    return (
    <>
    <div className={props.first ? "row mt-4" : "row"}>
        <table  style={{width: "100%", height: "100%"}} cellSpacing="10px" cellPadding="10px">
        <tbody>
          <tr><td><h5><a href={"./notice/"+data.year+"/"+data.month+"/"+data.url_id}>{data.title}</a></h5></td></tr>
          <tr><td><h6>{data.description}</h6></td></tr>
        </tbody>
        </table>
      </div>
    <hr className="mt-1 mb-1 bg-light"/>
    </>
    )
}