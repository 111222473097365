import Nav from '../components/Nav.js'
import Footer from '../components/Footer'
import { get } from "../adapters/Api"
import ReactHtmlParser from 'react-html-parser'; 
import { DiscussionEmbed, CommentCount } from "disqus-react"
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

export default function Notice(){

    const [notice, setNotice] = useState([]);

    const { year } = useParams();
    const { month } = useParams();
    const { id } = useParams();

    useEffect(()=>{

        if (year && month && id){
            let arg = {
                endPoint : 'news',
                data : {
                    year : year, 
                    month : month, 
                    id : id
                }
            }
            get(arg).then(res => setNotice(res.data[0]))
            console.log(notice)
        }
    },[year, month, id]);

    const formatDate = (strDate) => {
        let noticeDate = new Date(strDate),
        dia  = noticeDate.getDate().toString().padStart(2, '0'),
        mes  = (noticeDate.getMonth()+1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
        ano  = noticeDate.getFullYear();
        return mes+"/"+dia+"/"+ano;
    }

    return (
        <>
        <Nav/>

        <div className="container" style={{marginTop:"70px"}}>
            <div className="row">
            { notice?.url_img && 

                <div style={{maxHeight: "400px", width: "100%", overflow: "hidden"}}>
                <img 
                        src={"https://i.imgur.com/"+notice.url_img}
                        width={"100%"}
                        alt="image news"
                        className="img-fluid"
                />
                </div>

                }
            </div>
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                    <h1>{notice.title}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-5">
                    <p>
                        {ReactHtmlParser (notice.text) }
                    </p>
                </div>
            </div>
            { notice?.url_video && 
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe 
                            width="853" 
                            height="480" 
                            src={"https://www.youtube.com/embed/"+notice.url_video+"?rel=0&amp;showinfo=0"} 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            >
                        </iframe>
                    </div>
                </div>
            </div>
            }
            
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                    <p align="left"><font color="#000000"> By Administrador | {formatDate(notice.created_at)} |&nbsp;
                    <CommentCount
                        shortname='digitalworldpop'
                        config={{ url: "https://acarlosadvocacia.com.br/notice/"+notice.year+"/"+notice.month+"/"+notice.url_id, identifier: notice.created_at, title: notice.title}}
                    > comments</CommentCount></font></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-5 text-center">
                <DiscussionEmbed
                    shortname="digitalworldpop"
                    config={{ url: "https://acarlosadvocacia.com.br/notice/"+notice.year+"/"+notice.month+"/"+notice.url_id, identifier: notice.created_at, title: notice.title, language: "en_US" }}
                />
                </div>
            </div>
        </div>
       <Footer/>
        </>
    )
}