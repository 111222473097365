import ArticleItem from "./ArticleItem";
import {get} from '../../adapters/Api'
import React, { useState, useEffect } from 'react';


export default function Articles(){

  const [notices, setNotices] = useState([]);


  useEffect(()=>{
    let arg = {
      endPoint : 'articles'
    }
    get(arg).then(res => setNotices(res.data));
  },[]);

  return (
      <div className="row" style={{padding:"10px"}}>
        <div className="col-md-12"><h3 style={{fontWeight:"bold"}}>Artigos</h3>
          {notices.map((item, key) =>
            key === 0 ? <ArticleItem first data={item} key={key}/> : <ArticleItem data={item} key={key}/>
          )}
        </div>
      </div>
    )
}