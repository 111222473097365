import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'

function Item(props)
{
    return (
        <Paper>
            <img src={props.item.image} alt={props.item.name} className="img-fluid" width="100%"/>
        </Paper>
    )
}

export default function Painel() {

    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!",
            image : "https://www.sitedeadvocacia.com.br/modelos/9/img/full_banner_01.jpg"
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            image : "https://www.sitedeadvocacia.com.br/modelos/9/img/full_banner_02.jpg"
        },
        {
            name: "Random Name #3",
            description: "Hello World!",
            image : "https://www.sitedeadvocacia.com.br/modelos/9/img/full_banner_03.jpg"
        },
        {
            name: "Random Name #4",
            description: "Hello World!",
            image : "https://www.sitedeadvocacia.com.br/modelos/9/img/full_banner_04.jpg"
        }
    ]

    return(
            <div className="Container text-center" style={{'background-color' : '#c7c0b8'}}>
                <Carousel animation="slide" interval={10000} fullHeightHover={true} >
                    {
                        items.map( item => <Item item={item} /> )
                    }
                </Carousel>
            </div>
    );
}

