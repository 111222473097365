import Nav from '../components/Nav';
import Footer from '../components/Footer';
import News from '../components/News';
import Articles from '../components/Articles';

export default function EmFoco () {
    return (
    <>
    <Nav/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px", height:"210px", marginTop:"70px", verticalAlign:"middle"}}>
        <br/><br/><br/>
        <h1 style={{fontWeight:"bold"}}>PUBLICAÇÕES</h1>
    </div>
    <div className="container">
        <div className="row" >
            <div className="col-md-8">
                <News/>
            </div>
            <div className="col-md-4">
                <Articles/>
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}