import Nav from '../components/Nav';
import Footer from '../components/Footer';

export default function Solucoes () {
    return (
    <>
    <Nav/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px", height:"210px", marginTop:"70px", verticalAlign:"middle"}}>
        <br/><br/><br/>
        <h1 style={{fontWeight:"bold"}}>SOLUÇÕES</h1>
    </div>
    <div className="container">
        <div className="row" >
            <div className="col-md-12 text-center"  style={{fontSize:"24px",margin:"30px"}}>
            <h3>OFERECEMOS SERVIÇOS E SOLUÇÕES DE GOVERNANÇA TRIBUTÁRIA<br/> ATRAVÉS DE EMPRESAS ASSOCIADAS ESPECIALIZADAS EM CADA ÁREA</h3>
            </div>
        </div>
        <div className="row" style={{margin:"20px"}}>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            <h1 style={{fontWeight:"bold"}}>CONTENCIOSO</h1>
            <p>Audiências online ou presenciais e processos.</p>
            </div>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            Atuação através de medidas judiciais ativas e passivas pró-contribuinte, analisando e identificando prováveis irregularidades e oportunidades junto aos tribunais, sempre buscando a otimização dos resultados aos seus clientes.
            Assessoria aos seus clientes, com vistas à implantação de rotinas preventivas que possam evitar o litígio, bem como análise dos riscos envolvidos no processo judicial.
            Análise e realização de medidas a serem tomadas por tipo de ação
            </div>
        </div>
        <div className="row">
            <div className="col-md-12"  style={{paddingLeft:"50px",paddingRight:"50px"}}>
                <hr style={{borderTop: "2px solid black"}}/>
            </div>
        </div>
        <div className="row" style={{margin:"20px"}}>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            <h1 style={{fontWeight:"bold"}}>ASSESSORIA</h1>
            <p>Serviços tributários, empresariais e demais.</p>
            </div>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            Direito Tributário, Holding Familiar, Direito Administrativo, Direito Empresarial
            </div>
        </div>
        <div className="row">
            <div className="col-md-12"  style={{paddingLeft:"50px",paddingRight:"50px"}}>
                <hr style={{borderTop: "2px solid black"}}/>
            </div>
        </div>
        <div className="row" style={{margin:"20px"}}>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            <h1 style={{fontWeight:"bold"}}>DEMANDAS TRIBUTÁRIAS E FISCAIS</h1>
            <p>Recuperação fiscal judicial.</p>
            </div>
            <div className="col-md-6 text-left" style={{fontSize:"24px",padding:"30px"}}>
            Diagnóstico da empresa com relação à análise do recolhimento incorreto de tributo, com a identificação dos créditos e conseqüente implementação do processo judicial junto ao Estado.

O trabalho jurídico é realizado junto as principais teses existentes ligadas às decisões dos tribunais ou aquelas que venham a ocorrer, conforme regime jurídico específico.
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}