import Nav from '../components/Nav';
import Footer from '../components/Footer';

export default function AntonioCarlos () {
    return (
    <>
    <Nav/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px", height:"210px", marginTop:"70px", verticalAlign:"middle"}}>
        <br/><br/><br/>
        <h1 style={{fontWeight:"bold"}}>ANTÔNIO CARLOS DE ASSIS</h1>
    </div>
    <div className="container">
        <div className="row" >
            <div className="col-md-12 text-center" style={{fontSize:"24px",margin:"30px"}}>

                <img src="./avatar.png" className="rounded-circle" style={{width:"30%"}}/>

                <h2  style={{fontWeight:"bold"}}>Antonio Carlos de Assis</h2><br/>
                <p>Advogado e consultor. Diretor.</p>
            </div>
        </div>
        <div className="row" >
            <div className="col-md-12 text-justify" style={{fontSize:"24px",margin:"30px"}}>

                <p>Nasci em 1960 em Campinas-SP, casado, 4 filhos, 4 netas. Atuei como advogado tributarista desde 1996 especialmente para as PMEs (Pequenas e Médias Empresas). Tive oportunidade de ser consultor jurídico tributário em diversos Estados do Brasil, principalmente Mato Grosso em entidades empresariais como CDL (Câmara de Dirigentes Lojistas), Federações Industriais, Associações de Atacadistas e Distribuidores e Sindicatos empresariais. Fui professor de Direito e Coordenador de pós-graduação em várias Universidades e Institutos. Tive oportunidade de trabalhar também com Direito Empresarial e Direito Internacional Privado. No campo metafísico fui membro ativo da Sociedade Brasileira de Eubiose de 1977 até 2003 e atualmente pertenço à ARLS Portal de Aquarius. Nessas escolas me dediquei aos estudos espirituais como livre pensador e tal qual um Cavaleiro Medieval, sempre em busca do Santo Graal.
No campo da Filosofia Pura estudei Filosofia Clínica através do instituto Packter visando aplicar esses conhecimentos nas minhas oficinas nas áreas de meu domínio e me aventurar, como hobby, na produção de livros infantis.
Por fim tive a honra de ser nomeado Embaixador da Paz no Brasil pelo CERCLE UNIVERSEL DES AMBASSADEURS DE LA PAIX.</p>  


<br/><h3>ESPECIALIZAÇÃO</h3><br/>
<p>Graduação: Direito pela Pontifícia Universidade Católica de Campinas (PUCC) em 1984
MBA: Gestão de Negócios pela Universidade Federal de Mato Grosso do Sul (UFMS) em 2004
Mestrado: Direito na área de Direitos do Estado no Estado Democrático de Direito pela Universidade de Franca (UNIFRAN) em 2004</p>


<br/><h3>INSCRIÇÃO OAB/SP</h3><br/>
<p>Número do cadastro: 111.434</p>
<p>Cadastrado em: 1987</p>


<br/><h3>REFERÊNCIAS</h3><br/>

<p>Diretor Presidente da Associação Brasileira de Contribuintes – ABCONT, professor de Direito Tributário no Instituto Brasileiro de Direito – Ibijus desde 2014, fui professor de Direito e coordenador do núcleo de pós-graduação da Faculdade Cathedral de Barra do Garças-MT de 2012 a 2013, professor substituto na Faculdade de Direito da Universidade Federal de Mato Grosso (UFMT) em 2001 e 2005, Diretor Administrativo e membro do Conselho Catedrático da Academia Matogrossense de Direito Constitucional (2010-2011), presidente da Comissão de Estudos Constitucionais da OAB/MT de 2005 a 2010, membro do Conselho Curador da Escola Superior de Advocacia da OAB/MT triênio (2008 a 2010), presidente da Associação Brasileira de Contribuintes desde 2009, membro suplente do Conselho Administrativo Tributário (CAT) do Estado de Mato Grosso em 2006, coordenador dos cursos de pós-graduação em Direito da Universidade de Cuiabá (UNIC) em 2003, professor da Escola Superior do Ministério Público e Faculdade Afirmativo em 2000, atuei como advogado pela Câmara de Dirigentes Lojistas de Cuiabá-MT em 1999 e de Barra do Garças-MT de 1997 a 1999 e ministrei palestras sobre Direito Comercial e Direito Tributário em Câmaras de Dirigentes Lojistas e Associações Comerciais em mais de 20 cidades.</p>
 
<p>Como professor atuei nas disciplinas de Direito Tributário, Direitos Humanos, Direito das Relações Internacionais, Teoria Geral do Processo, Introdução ao Estudo de Direito e Filosofia do Direito.</p>

<p>Na área empresarial fui diretor e consultor jurídico de uma empresa de comércio exterior tendo angariado experiência fora do país nas questões de relações internacionais com trabalhos realizados na China, Índia, África do Sul, Bolívia, Canadá, Portugal e Espanha.</p>
Tenho artigos publicados em sites e revistas jurídicas e escrevi semanalmente durante dois anos para o jornal Diário de Cuiabá acerca de questões tributárias.

<p>Sou autor do livro "Comércio eletrônico: aspectos contratuais da relação de consumo" publicado pela editora Juruá em 2005.
Atualmente dirijo também a empresa MRelvas Educação e Desenvolvimento Humano com projetos educacionais nas áreas do Direito Tributário, Filosofia do Direito, Filosofia e Metafísica. Também ministro cursos de extensão em Direito Tributário através da Universidade Tributária 4.0 (comunidade de advogados tributaristas) e eventualmente ministro aulas de pós-graduação em universidades brasileiras como professor convidado. Faço consultoria jurídica e estou trabalhando no meu segundo livro jurídico cujo título será Direito Tributário - Crítica e Prática.</p>
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}