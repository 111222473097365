import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import Home from "./pages/Home.js";
import QuemSomos from "./pages/QuemSomos.js";
import Escritorio from "./pages/Escritorio.js";
import AntonioCarlos from "./pages/AntonioCarlos.js";
import ModeloNegócio from "./pages/ModeloNegocio.js";
import Solucoes from "./pages/Solucoes.js";
import Advogados from "./pages/Advogados.js";
import Parceiros from "./pages/Parceiros.js";
import Fornecedores from "./pages/Fornecedores.js";
import EmFoco from "./pages/EmFoco.js";
import Contato from "./pages/Contato.js";
import Notice from "./pages/Notice.js";

function App() {
  return (
    <Router forceRefresh={true}>
      <Route exact path="/" component={Home} />
      <Route exact path="/quemsomos" component={QuemSomos} />
      <Route exact path="/escritorio" component={Escritorio} />
      <Route exact path="/antoniocarlos" component={AntonioCarlos} />
      <Route exact path="/modelonegócio" component={ModeloNegócio} />
      <Route exact path="/solucoes" component={Solucoes} />
      <Route exact path="/advogados" component={Advogados} />
      <Route exact path="/parceiros" component={Parceiros} />
      <Route exact path="/fornecedores" component={Fornecedores} />
      <Route exact path="/emfoco" component={EmFoco} />
      <Route exact path="/contato" component={Contato} />
      <Route exact path="/notice/:year/:month/:id" component={Notice} />
    </Router>
  );
}

export default App;
