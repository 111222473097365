import React, { useState, useEffect } from 'react';
import {Link, withRouter} from 'react-router-dom'


export default function Nav(){

    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top">
            <div className="navbar-collapse justify-content-center" id="navbarsExample11">
                <div className="container" style={{width:"150%"}}>
                    <div className="row" style={{width:"110%"}}>
                        <div className="col-2">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">
                                        <img src="https://acarlosadvocacia.com.br/logo_white.png" 
                                            style={{maxWidth:"270px",width:"110%"}}
                                            alt="image news"
                                            className="img-fluid"
                                        />
                                        <span className="sr-only">(current)</span></a>
                                </li>
                            </ul>
                        </div>
                        <ul className="navbar-nav ml-auto" style={{padding:"20px", fontSize:"20px", fontWeight:"bold"}}>
                                <li className="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    QUEM SOMOS
                                </a>
                                <div class="dropdown-menu bg-dark" aria-labelledby="navbarDropdownMenuLink" style={{fontSize:"15px", fontWeight:"bold"}}>
                                    <Link to="/quemsomos" className="nav-link">QUEM SOMOS</Link>
                                    <Link to="/antoniocarlos" className="nav-link">ANTONIO CARLOS</Link>
                                </div>
                                </li>
                                <li className="nav-item"><Link to="/solucoes" className="nav-link">SOLUÇÕES</Link></li>
                                <li className="nav-item"><Link to="/advogados" className="nav-link">PARCEIROS</Link></li>
                                <li className="nav-item"><Link to="/emfoco" className="nav-link">EM FOCO</Link></li>
                                <li className="nav-item"><Link to="/contato" className="nav-link">CONTATO</Link></li>
                                <li className="nav-item"><a href="https://adm.casadotributo.com.br/login" className="nav-link">LOGIN</a></li>
                            </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}