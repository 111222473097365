export default function NoticeItem(props){
  let data = props.data;
  return (
      <div className={props.first ? "mt-4" : ""}>
          <div className="row d-none d-sm-block">
            <table style={{width: "100%"}} cellSpacing="10px" cellPadding="10px" >
            <tbody>
              <tr>
                <td rowSpan="2" style={{width: "40%"}}>
                    <a href={"./notice/"+data.year+"/"+data.month+"/"+data.url_id}>
                      <img src={"https://i.imgur.com/"+data.thumbnail} className="img-fluid"/>
                    </a>
                  </td>
                <td style={{verticalAlign: "top"}}><h4>{data.title}</h4>{data.description}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: "bottom"}}>Author: Administrador</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="row d-sm-none">
            <table style={{width: "100%", height: "100%"}} cellSpacing="5px" cellPadding="5px">
            <tbody>
              <tr>
                <td style={{height: "30%", verticalAlign: "top"}}><h4>{data.title}</h4></td>
              </tr>
              <tr>
                <td style={{height: "30%"}}>
                <div style={{height: "200px", width: "100%", overflow: "hidden"}}>
                  <a href={"./notice/"+data.year+"/"+data.month+"/"+data.url_id}>
                    <img src={"https://i.imgur.com/"+data.thumbnail} className="img-fluid"/>
                  </a>
                </div>
                </td>
              </tr>
              <tr>
                <td style={{height: "30%", verticalAlign: "top"}}>{data.description}</td>
              </tr>
              <tr>
                <td style={{height: "30%", verticalAlign: "bottom"}}>Author: Administrador</td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr className="mt-2 mb-2 bg-dark"/>
      
      </div>
  )
}