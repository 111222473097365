import NoticeItem from './NoticeItem';
import { get } from '../../adapters/Api'
import React, { useState, useEffect } from 'react';

export default function News(){

  const [notices, setNotices] = useState([]);

  useEffect(()=>{
    let arg = {
      endPoint : 'notices'
    }
    get(arg).then(res => setNotices(res.data));
  },[]);

    return (    
        <div style={{padding:"20px"}}>
        <h3 style={{fontWeight:"bold"}}>Notícias</h3>
    
{ /*
        <ul className="nav nav-tabs card-header-tabs mt-5" id="myTab" role="tablist">
          <li className="nav-item">
              <a className="nav-link" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Geral</a>
          </li>
          <li className="nav-item">
              <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Entretenimento</a>
          </li>
          <li className="nav-item">
              <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="Three" aria-selected="false">Ciências</a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
   */
}
    
          {notices.map((item,key)=>
            key===0 ? <NoticeItem data={item} key={key} first/> : <NoticeItem data={item} key={key}/>)
          
          }
          
{/*
          <nav aria-label="Page navigation example" className="mt-3">
            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="#" tabIndex="-1">Previous</a>
              </li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item"><a className="page-link" href="#">2</a></li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className="page-item">
                <a className="page-link" href="#">Next</a>
              </li>
            </ul>
          </nav>          
        </div>
        <div className="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
          <h5 className="card-title">Tab Card Two</h5>
          <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#" className="btn btn-primary">Go somewhere</a>              
        </div>
        <div className="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
          <h5 className="card-title">Tab Card Three</h5>
          <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#" className="btn btn-primary">Go somewhere</a>              
        </div>
      </div>
  */
}
      </div>
    )
    }