import Nav from '../components/Nav';
import Footer from '../components/Footer';

export default function QuemSomos () {
    return (
    <>
    <Nav/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px", height:"210px", marginTop:"70px", verticalAlign:"middle"}}>
        <br/><br/><br/>
        <h1 style={{fontWeight:"bold"}}>QUEM SOMOS</h1>
    </div>
    <div className="container">
        <div className="row" >
            <div className="col-md-12" style={{marginTop:"70px",marginBottom:"70px", fontSize:"24px", textAlign:"justify"}}>

            <p>O Escritório de advocacia foi fundado pelo advogado Antônio Carlos de Assis, cuja trajetória na área tributária se iniciou no ano de 2012, direcionada principalmente para atendimento às pequenas e médias empresas (PME), beneficiadas com o projeto das Grandes Teses, as quais marcaram seu profundo conhecimento e viabilizaram inúmeras parcerias, ampliando o atendimento para clientes de todo país, através de uma talentosa equipe de Advogados.</p>

            <p>São oferecidos serviços jurídicos preventivos e corretivos, no âmbito administrativo e judicial. O Escritório de advocacia é integrado por Advogados Tributaristas sediados em todas as regiões do Brasil.</p>

            <p>A equipe de profissionais parceiros participa do projeto de desenvolvimento do escritório para atender nossos clientes com tecnologia e conhecimento que há de mais avançado no mercado.</p>
            
            <p>A satisfação dos clientes é a principal preocupação de nossa comunidade de advogados.</p>


            <h3 style={{marginTop:"70px",marginBottom:"70px", fontWeight:"bold"}}>Nossos Valores</h3>

            <p>Constituida por gente que levou adiante o sonho de empreender. Acreditamos no nosso projeto e transformamos todos os dias ideias de negócio em boas oportunidades. A contabilidade é a ferramenta que utilizamos para mantermos o alinhamento de interesses com os gestores e caminharmos juntos em direção ao sucesso e a prosperidade empresarial.</p>

            <h3 style={{marginTop:"70px",marginBottom:"70px", fontWeight:"bold"}}>Missão, visão e valores</h3>

            <ul>
                <li>ser parceira do empreendedor no mundo dos negócios, atender com eficiência e transparência as demandas empresariais e gerar oportunidades de crescimento para as pessoas, empresas e para o mundo em que vivemos.</li>
                <li>Valorização e promoção da contabilidade no ambiente de negócios. Crescimento contínuo, consistente e acelerado para a Impisa e para nossos clientes.</li>
                <li>Transparência, Eficiência, Crescimento e Liberdade.</li>
            </ul>


            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}