import Nav from '../components/Nav';
import Footer from '../components/Footer';

export default function Advogados () {
    return (
    <>
    <Nav/>
    <div className="Container text-center" style={{'background-color' : '#c7c0b8', padding:"30px", height:"210px", marginTop:"70px", verticalAlign:"middle"}}>
        <br/><br/><br/>
        <h1 style={{fontWeight:"bold"}}>PARCEIROS</h1>
    </div>
    <div className="container">
        <div className="row" >
            <div className="col-md-12 text-justify" style={{fontSize:"24px",margin:"30px"}}>
            <p>O Escritório de Advocacia integra um pool de parceiros prestadoros de serviços na área do Direito Tributário, afins e complementares. São pessoas jurídicas legalmente constituídas e independentes, porém, operacionalmente integradas.</p>
            <p>Temos atuação em todo território nacional.</p>
            </div>
            <div className="col-md-8 text-left"  style={{fontSize:"24px",margin:"30px"}}>
        <p>Seja nosso parceiro:</p>
        <p>
                    <div class="form-group">
                        <input type="text" placeholder="Nome*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="E-mail*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="OAB*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="Telefone" className="form-control" />
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="Descrição Atuação*" className="form-control" />
                    </div>
                    <div class="form-group">
                        <textarea placeholder="Mensagem*" className="form-control"></textarea>
                    </div>
                    <input type="button" value="Enviar" style={{'background-color' : '#c7c0b8', fontWeight:"bold"}} className="btn" />
                    </p>
                </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}