export default function Footer(){
    return (
            <div className="footer" style={{'background-color' : '#151515'}}>
                <div className="col-md-12 text-center"  style={{fontSize : '20px'}}>
                    <h4 style={{color: "white"}}>CONTATO</h4>
                    <p style={{color: "white"}}>Telefone: (31) 97160-0615</p>
                    <h5 style={{color: "white"}}>INFORMAÇÕES DE CONTATO:</h5>
                    <p style={{color: "white"}}>Emails: contato@acarlosadvocacia.com.br</p>
                    <h5 style={{color: "white"}}>ENDEREÇO:</h5>
                    <p style={{color: "white"}}>rua coronel praes, 349/401, sagrada família, bh/mg, CEP 31035-590</p>
                </div>
                <div className="sub-footer" style={{backgroundColor: "black"}}>
                    <div className="col-md-12 text-center">
                        <h6 style={{color: "white"}}>  &copy; 2016 Nome da Sua Empresa | Design: Site de Advocacia</h6>
                    </div>
                </div>
            </div>
            
    )
}